<template>
    <h4>{{ $t('general.administracion') }}</h4>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Clientes')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-user-friends"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.clientes') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Comerciales')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-user-tag"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.comerciales') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Compañías')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-briefcase"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.companias') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Especialidades')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-tags"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.especialidades') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Estados Pendientes')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-highlighter"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Estados pendientes</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Mensajes Predefinidos')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"> <i class="fas fa-envelope-open-text"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.mensajespredefinidos') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Operarios')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-hard-hat"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.operarios') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Pedidos')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-boxes"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Pedidos</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Peritos')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-user-tie"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.peritos') }}</span>
                        </div>
                    </a>
                </div>

                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Proveedores')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-truck-loading"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.proveedores') }}</span>
                        </div>
                    </a>
                </div>
                <div v-if="factoadmin" class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Tipos de daño')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-clipboard-list"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Tipos de daño</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Usuarios')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-address-card"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.usuarios') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="equipos &&factoadmin">
                    <a @click.prevent="abrirConfiguracion('EquiposMarcas')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-tv"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.equipos') }}/{{ $t('general.marcas') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="factoadmin && tipos">
                    <a @click.prevent="abrirConfiguracion('GestorTipos')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-th-list"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Gestor de tipos</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4" v-if="factoadmin && tipos">
                    <a @click.prevent="abrirConfiguracion('CoberturasOperarios')" href="#" class="info-box">
                        <span class="info-box-icon bg-warning"><i class="fas fa-draw-polygon"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Coberturas operarios</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
export default({
    props:['factoadmin'],
    setup() {
        
    },
    data(){
        return {
            equipos: false,
            tipos: false
        }
    },
    mounted(){
        this.comprobarEquipos();
        this.comprobarTipos();
    },
    methods: {
        abrirConfiguracion(configuracion){
            this.$router.push({
                name: configuracion
            });
        },
        async comprobarEquipos(){
            const api = new PwgsApi();
            const ia = await api.get('parametros-pwgs/gestion_de_equipos');
            if(ia['gestion_de_equipos']==1){
                this.equipos=true;
            }
        },
        async comprobarTipos(){
            const api = new PwgsApi();
            const ia = await api.get('parametros-pwgs/gestion_tipos');
            if(ia['gestion_tipos']==1){
                this.tipos=true;
            }
        }
    }
})
</script>
